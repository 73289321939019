.bg-about {
	position: relative;
	z-index: 1;
}

.bg-about::before {
	content: '';
	position: absolute;
	inset: 0;
	background-image: url('~images/bg_about_us.png');
	background-size: cover;
	opacity: 0.3;
	z-index: -1;
}

.bg-collab {
	position: relative;
	z-index: 1;
}

.bg-collab::before {
	content: '';
	position: absolute;
	inset: 0;
	background-image: url('~images/bg_about_us2.png');
	background-size: 100% 480px;
	background-repeat: no-repeat;
	opacity: 0.3; 
	z-index: -1;

	@media (min-width: 1180px) {
		background-size: 100% 608px;
	}
}

.custom-margin {
  margin: 0 calc((100vw - 100%) / 2 * -1);
}

.collab-img {
  display: inline-block;
  width: auto !important;
} 

.shadow-left {
  position: absolute;
	width: 15%;
	height: 100%;
	left: 0;
	background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.05));
	z-index: 10;
}

.shadow-right {
	position: absolute;
	width: 15%;
	height: 100%;
	right: 0;
	background: linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.05));
	z-index: 10;
}